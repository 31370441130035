@font-face {
	font-family: 'GT-Walsheim';
	src: url('../fonts/GT-Walsheim-Bold.ttf') format('truetype');
	font-weight: bold;
}
@font-face {
	font-family: 'GT-Walsheim';
	src: url('../fonts/GT-Walsheim-Regular.ttf') format('truetype');
	font-weight: normal;
}

@keyframes bob {
	0% {transform: translateY(0rem);}
	50% {transform: translateY(0.25rem);}
	100% {transform: translateY(0rem);}
}

* {
	box-sizing: border-box;
}

a:focus {
	outline: 0;
}

body {
	font-size: 100%;
	line-height: 1.5;
	font-family: 'GT-Walsheim';
	font-weight: normal;
	background: #f5f5f5;
}

h2 {
	font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: -1.5px;
    text-align: left;
    font-size: 3.75rem;
    text-align: center;
	margin-bottom: 30px;
	color: #a0c800;
}

header {
	background: #f5f5f5;
    padding: 1rem 0 3rem 1rem;
	display: flex;
	align-items: center;
    justify-content: center;
}

.bg-image {
	position: absolute;
	top: 0;
	right: 0;
	background-image: url('../images/homepage-hand-aw.png');
	background-repeat: no-repeat;
	background-position: 100% 0%;
	background-position: top right;
	z-index: 10;
    z-index: 10;
    width: 20rem;
    height: 26rem;
    background-size: contain;
}


.video-wrapper, 
.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}
.video-wrapper {
    position: relative;
	width: 100%;
    height: calc(90vh - 154px);
}
.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.scroll-to {
	position: absolute;
	bottom: 2rem;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #000;
	font-weight: bold;
	z-index: 100;

	svg {
		width: 3rem;
		height: 3rem;
		animation: bob infinite both 1s ease;
	}
}

.content {
	padding: 11rem 4rem 4rem 4rem;
	.content-inner {
		width: 100%;
		h2 {
			max-width: 45rem;
			margin: 0 auto 2.5rem auto;
		}
	}
	svg {
		margin-bottom: 1.5rem;
	}
	p {
		font-size: 1.3125rem;	
		line-height: 1.75rem;
		margin-bottom: 2.5rem;
	}
	.button {
		border-radius: 4em;
		border: solid 2px #9fc800;
		color: #000;
		padding: 0.75rem 1rem;
		font-size: 1.125rem;
		font-weight: bold;
		text-decoration: none;
		display: inline-block;
		font-weight: bold;
		transition: all 0.3s ease;
		&:hover {
			background: #9fc800;
			color: #fff;
			border-color: #fff;
		}
	}
}
.numbers-wrapper {
	padding-top: 4rem;
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	.number {
		position: relative;
		width: 16.6666666667%;
		cursor: pointer;
		transition: all 0.3s ease;
		display: block;
		overflow: hidden;
		border: solid 3px #fff;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: all 0.3s ease;
		}
		&:hover {
			img {
				transition: all 0.3s ease;
				transform: scale(1.3)
			}
		}
	}
}


.fancybox-content {
	padding: 0!important;
}

@media(min-width: 1440px) {
	.numbers-wrapper .number {
		height: 18rem;
	}
}

@media(max-width: 1440px) {
	.numbers-wrapper .number {
		height: 16rem;
	}
}

@media(max-width: 992px) {
	.numbers-wrapper .number {
		height: 10rem;
	}
}

@media(max-width: 768px) {
	.numbers-wrapper .number {
		height: 8rem;
	}
}


@media(max-width: 640px) {
	.numbers-wrapper .number {
		height: 5rem;
		width: 16.6666666667%;
	}
	.content {
		padding-left: 2rem;
		padding-right: 2rem;
		.content-inner h2 {
			font-size: 2.75rem;
		}
	}
}

@media(max-width:480px) {
	header {
		padding: 0 0 0.5rem 0;
		svg {
			width: 30%;
		}
	}
	.content {
		padding: 6rem 1.5rem 1.5rem 1.5rem;
		.content-inner {
			width: 100%;
			h2 {
				font-size: 1.5rem;
			}
		}
	}
	.bg-image {
		top: 18rem;
		right: -50px;
		background-position: right;
		width: 100%;
		height: 20rem;
		background-size: contain;
	}
	.fancybox-container {
		width: 90%;
		height: auto;
	}
}

footer {
	text-align: center;
	padding-bottom: 1.5rem;
	p {
		margin: 5px 0 20px;
    	color: #969190;
    	font-size: 1rem;
    	line-height: 1.3em;
	}
	a {
		font-weight: bold;
		text-decoration: none;
		color: #969190;
	}
	.social-icons {
		display: inline-flex;
		li {
			margin-right: 8px;
			a {
				color: #706b6a;
    			font-size: 1.1em;
    			font-weight: 600;
				text-decoration: none;
				svg {
					width: 20px;
    				height: 20px;
					display: block;
					path {
						fill: #706b6a;
					}
				}
			}
		}
	}
}

// Alternative Section Styles

.cover-section {
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		background: #146B3A;
		transition: all 0.5s ease;
	}
	&.show-me {
		&:before {
			height: 100vh;
		}
	}

	h1 {
		position: relative;
		text-align: center;
		font-size: 5.5rem;
		color: #ffff;
		font-weight: bold;
		width: 80%;
	}
}

.alternative .content {
	padding: 3rem 4rem;
}

.alternative header {
	position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
	height: 5.5rem;
	padding: 0;
}

.alternative header svg {
	padding: 1rem;
}